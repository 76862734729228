
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { BAIconsMixin, AuthMixin, StringsMixin, AthleteRoutingMixin, PartnersRoutingMixin, MyAthleteMixin, VuetifyMixin, ShowcaseEventMixin } from '@/mixins';
import { UpgradeNowMixin } from '@/mixins/UpgradeNowMixin';

import BestAthletesLogo from '../ui/BestAthletesLogo.vue';
import EventsNavItems from './EventsNavItems.vue';

import ComparativeDataProvider from '../hoc/ComparativeDataProvider.vue';
import HolidaySale from '../promotions/HolidaySale.vue';

@Component({
	components: {
		BestAthletesLogo,
		EventsNavItems,
	},
})
export default class AthleteSideNav extends Mixins(BAIconsMixin, AuthMixin, StringsMixin, AthleteRoutingMixin, PartnersRoutingMixin, MyAthleteMixin, UpgradeNowMixin, VuetifyMixin, ShowcaseEventMixin){
	@Prop({ default: true, type: Boolean }) value: boolean;

	mini = false;
	listHome: boolean = false;
	listTeams: boolean = false;
	listPerformance: boolean = true;
	listRecruitment: boolean = false;
	listCalendar: boolean = false;
	listPartners: boolean = false;

	get HasEvents(): boolean {
		if( !this.TheAthleteProfile ) return false;
		return this.TheAthleteProfile.hasEvents || this.TheAthleteProfile.hasFutureSchedule();
	}

}
